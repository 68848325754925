<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'Whatsapp'" :subtitle="'Sucesso'" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <router-link to="/whatsapp/jv/send" class="btn btn-primary">
            <i class="icon dripicons-plus color-white"></i>Nova Mensagem
          </router-link>
        </div>
        <div class="card-body">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            Sua mensagem foi registrada com sucesso!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/rebranding/PageHeader.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      sms: [],
    };
  },
  mounted() {
    this.sms = this.$route.params.sms;
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}
</style>
